* {
    margin: 0;
    padding: 0;
}

html, body {
    height: 100%;
}

.wrapper {
    height: 85vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.user-container {
    background: #eeeeee;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    max-height: 50px;
}

.user-container label {
    font-size: 14px;
    margin-right: 1rem;
}

.user-container input {
    border-radius: 3px;
    border: none;
    height: 100%;
}

.display-container {
    display: flex;
    height: 93%;
    flex-direction: column;
    background: #ffffff;
    overflow-y: auto;
    border-radius: 20px;
    box-shadow: 0px 4px 5px 0px #EDEEEF;
}

.input-container {
    flex: 1;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    height: 7%;
}

.input-container span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.3rem;
    width: 100%;
}

.chatting-input {
    background: #ececec;
    font-size: 12px;
    height: 100%;
    border: none;
    padding: 5pt;
}

.chatting-file-input {
    font-size: 12px;
    border: none;
    padding: 5pt;
}

.send-button {
    background: #d6f9ff;
    border: none;
    height: 100%;
    border-radius: 3px;
}

.chatting-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.3rem;
    margin-top: 0.5rem;
}

.profile {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    flex: 1;
}

.profile .user {
    font-size: 10px;
    margin-bottom: 0.3rem;
}

.profile .image {
    border-radius: 50%;
    object-fit: cover;
    width: 50px;
    height: 50px;
}

.mt {
    display: flex;
    flex-direction: row;
}

.files {
    border-radius: 5px;
    padding: 0.5rem;
    font-size: 12px;
    margin: 0 5px;
}

.message {
    border-radius: 5px;
    padding: 0.5rem;
    font-size: 12px;
    margin: 0 5px;
}

.times {
    font-size: 8px;
}

.sent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: right;
}
.sent .profile {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 3px;
}

.sent .mt {
    display: flex;
    flex-direction: row-reverse;
}

.sent .message {
    background: #d6f9ff;
}
.sent .files {
    display: flex;
    flex-direction: row-reverse;
    max-width: 1240px;
    max-height: 1748px; 
}

.received {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.received .profile {
    display: flex;
    flex-direction: row;
    margin-left: 3px;
}

.received .message {
    background: #eeeeee;
}

.received .files {
    display: flex;
    flex-direction: row;
    max-width: 1240px;
    max-height: 1748px; 
}